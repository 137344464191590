import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-8">
      <div className="container mx-auto text-center">
        <p>
          <Link to="/about" className="text-gray-300 hover:text-white">
            About Us
          </Link>{' '}
          |
          <Link to="/terms" className="text-gray-300 hover:text-white">
            Terms of Use
          </Link>{' '}
          |
          <Link to="/privacy-policy" className="text-gray-300 hover:text-white">
            Privacy Policy
          </Link>
        </p>
        <p className="mt-4">Copyright © 2024 FlexGo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
