import React from 'react';

const Testimonials: React.FC = () => {
  return (
    <section id="testimonials" className="py-16 bg-gray-50">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-semibold text-gray-900">
          What Our Users Say
        </h2>
        <div className="mt-8 max-w-3xl mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <p className="italic text-lg">
              "FlexGo transformed how we manage our gym and trainers. The
              booking system is seamless, and the payments tracking is a
              lifesaver."
            </p>
            <h4 className="mt-4 font-bold text-green-600">— Gym Owner</h4>
          </div>
          <div className="mt-8 bg-white p-8 rounded-lg shadow-lg">
            <p className="italic text-lg">
              "Managing my students and sending them personalized workout plans
              is so easy with FlexGo. It's a game-changer for trainers."
            </p>
            <h4 className="mt-4 font-bold text-green-600">
              — Personal Trainer
            </h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
