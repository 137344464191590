import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { i18n } = useTranslation();

  // Função para trocar o idioma
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <HelmetProvider>
        {/* Helmet pode ser configurado globalmente */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Your site description here" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Your Site Title</title>
        </Helmet>

        <header>
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('pt')}>Português</button>
        </header>

        <Navbar />
        <main>{children}</main>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default MainLayout;
