import React from 'react';
import { Link } from 'react-router-dom';

const Pricing: React.FC = () => {
  return (
    <section id="pricing" className="py-16 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-900">
          Flexible Pricing Plans
        </h2>
        <p className="mt-4 max-w-2xl mx-auto text-lg">
          Choose the plan that best fits your business needs, whether you're
          managing a small studio or multiple large fitness spaces.
        </p>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold text-green-600">
              Basic Plan
            </h3>
            <p className="mt-4 text-gray-700">
              For individual trainers or small spaces
            </p>
            <p className="mt-4 text-4xl font-bold">
              $19<span className="text-lg">/month</span>
            </p>
            <ul className="mt-6 text-gray-600 text-left">
              <li>Manage 1 space</li>
              <li>Unlimited classes</li>
              <li>100 bookings/month</li>
            </ul>

            <Link
              to="/choose-plan"
              className="mt-6 inline-block bg-green-600 text-white font-bold py-3 px-8 rounded-full hover:bg-green-700"
            >
              Choose Plan
            </Link>
          </div>

          <div className="bg-gray-100 p-8 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold text-green-600">
              Professional Plan
            </h3>
            <p className="mt-4 text-gray-700">
              Best for gyms or multiple trainers
            </p>
            <p className="mt-4 text-4xl font-bold">
              $49<span className="text-lg">/month</span>
            </p>
            <ul className="mt-6 text-gray-600 text-left">
              <li>Manage up to 5 spaces</li>
              <li>Unlimited classes</li>
              <li>Unlimited bookings</li>
              <li>Advanced reporting</li>
            </ul>
            <Link
              to="/choose-plan"
              className="mt-6 inline-block bg-green-600 text-white font-bold py-3 px-8 rounded-full hover:bg-green-700"
            >
              Choose Plan
            </Link>
          </div>

          <div className="bg-gray-100 p-8 rounded-lg shadow-lg text-center">
            <h3 className="text-2xl font-semibold text-green-600">
              Enterprise Plan
            </h3>
            <p className="mt-4 text-gray-700">Custom solution for large gyms</p>
            <p className="mt-4 text-4xl font-bold">Contact Us</p>
            <ul className="mt-6 text-gray-600 text-left">
              <li>Custom space management</li>
              <li>Unlimited classes and bookings</li>
              <li>Dedicated support</li>
            </ul>
            <Link
              to="/contact-sales"
              className="mt-6 inline-block bg-green-600 text-white font-bold py-3 px-8 rounded-full hover:bg-green-700"
            >
              Contact Sales
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
