import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Testimonials from './pages/Testimonials';
import MainLayout from './layouts/MainLayout';
import Loading from './components/Loading';

const Home = React.lazy(() => import('./pages/Home'));

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Routes>
        </MainLayout>
      </Router>
    </Suspense>
  );
};

export default App;
