import React from 'react';
import { Link } from 'react-router-dom';

const navLinks = [
  { path: '/', label: 'Home' },
  { path: '/features', label: 'Features' },
  { path: '/pricing', label: 'Pricing' },
  { path: '/testimonials', label: 'Testimonials' },
];

const Navbar: React.FC = () => {
  return (
    <nav className="bg-white shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-green-600 text-2xl font-bold">FlexGo</div>
        <ul
          className="flex space-x-6 text-gray-700"
          aria-label="Main Navigation"
        >
          {navLinks.map(link => (
            <li key={link.path}>
              <Link to={link.path} className="hover:text-green-600">
                {link.label}
              </Link>
            </li>
          ))}
          <li>
            <Link
              to="#"
              className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
            >
              Start Free Trial
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
