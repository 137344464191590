import React from 'react';

const Features: React.FC = () => {
  return (
    <section id="features" className="py-16 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-semibold text-gray-900">
          Powerful Features for Fitness Businesses
        </h2>
        <p className="mt-4 text-lg max-w-xl mx-auto">
          FlexGo provides everything you need to manage your spaces, trainers,
          and students with efficiency and ease.
        </p>
      </div>

      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg text-left">
          <h3 className="text-2xl font-semibold text-green-600">
            Space Management
          </h3>
          <p className="mt-4 text-gray-600">
            Create and manage fitness spaces, zones, and sub-spaces with
            flexible booking options and real-time availability.
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg text-left">
          <h3 className="text-2xl font-semibold text-green-600">
            Trainer Tools
          </h3>
          <p className="mt-4 text-gray-600">
            Personal trainers can create, manage, and promote classes, track
            student progress, and send personalized workout plans.
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg text-left">
          <h3 className="text-2xl font-semibold text-green-600">
            Payment and Revenue
          </h3>
          <p className="mt-4 text-gray-600">
            Monitor your earnings, manage payments, and offer discounts for
            trainers or students with just a few clicks.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;
